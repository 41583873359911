import React from 'react';
import './home.css';
import HeaderImg from '../../components/header-img';
import image from '../../assets/default-lawyer-1.jpg';
import bma from '../../assets/bma.jpg';
import amdd from '../../assets/amddf.jpg';
import logoWhite from '../../assets/logo_black.png';


export default function Home(props) {
  return (
    <main>
      <HeaderImg img={image} height="59vh" >
        <img src={logoWhite} className="logo-head" alt="Logo de Duque Banda Asociados"/> 
      </HeaderImg>
      <div className="section">
        <div className="lema-container">
          <h1> Defensa Jurídica </h1>
          <h2> Experiencia y Resultados </h2>
          <h3> Compromiso </h3>
          <p> Honestidad y respeto valores que nos caracterizan </p>
        </div>
        <div className="semblanza">
          <p>Nuestro estudio jurídico nace en diciembre de 2018 para brindar un servicio legal especializado que cubre las necesidades del sector empresarial, comercial y particular en las operaciones y actuaciones de su día a día que implican obligaciones y consecuencias jurídicas tanto personales como patrimoniales. </p>
          <p>Nuestra experiencia previa de más de doce años ha permitido estructurar nuestra firma con el fin de cumplir de manera profesional y especializada con los compromisos y requerimientos de nuestros clientes siempre orientados a resultados.</p>
          <div className="afiliations">
            <a href="http://www.bma.org.mx/" rel="noopener noreferrer" target="_blank"><img src={bma} alt="logo barra mexicana de abogados"/></a>
            <a href="https://amdf.org.mx/" rel="noopener noreferrer" target="_blank"><img src={amdd} alt="logo academia mexicana de derecho"/></a>
          </div> 
        </div>
      </div>
    </main>
  );
}
