import React from 'react';
import {
  BrowserRouter as Router, 
  Switch,
  Route,
  Link
  } from 'react-router-dom';
import Menu from '../components/menu/menu.js';
import './app.css';
import Home from './home';
import Content from './configuration-content.json';
import logo from '../assets/logo.png';
import Footer from './footer';
import Services from './services';
import Contact from './contact';

export default class App extends React.Component {

  render() {
    return (
      <Router>
        <header>
          <Link to="/"><img src={logo} alt="logo" /> </Link>
          <Menu menu={Content.menu} />
        </header>
         
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/servicios/:service">
            <Services services={Content.services} />
          </Route>
          <Route path="/nosotros">
            <Services services={Content.services} />
          </Route>
          <Route path="/contact">
            <Contact serviceApi={Content.serviceApi} sendTo={Content.sendTo}/>
          </Route>
        </Switch>

        <Footer footer={Content.footer} />
      </Router>
    );
  }
}
