import React from 'react';
import './contact-us.css';

export default class ContactUs extends React.Component {
   
  constructor(props) {
    super(props);
    this.sendTo = props.sendTo;
    this.serviceApi = props.serviceApi;
    this.state = {
      serviceApi: props.serviceApi, 
      email: '',
      name: '',
      phone: '',
      message: '',
      showMessage: false,
      sending: false,
      error: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = this._getValueFromInput(target);

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    const serviceApi = this.serviceApi;
    const sendTo = this.sendTo;
    const { phone, name, message, email } = this.state;
    const body = {
      phone: phone,
      name: name,
      message: message,
      mail: email,
      to: sendTo
    };
    this.setState({
      showMessage: true,
      sending: true
    });

    fetch(serviceApi, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response => {
      if(response.statusText === 'OK') {
        this.setState({
          sending: false
        });  
      } else {
        this.setState({
          error: true
        }); 
      }
    }).catch(error => {
      this.setState({
        error: true
      });
    });

    event.preventDefault();
  }

  _getValueFromInput(target) {
    if(!target){
      return null;
    } else if(target.type === 'checkbox') {
      return target.checked;
    } else {
      return target.value;
    }
  }

  _printMessage() {
    if(!this.state.showMessage) {
      return (<div></div>);
    } else if(this.state.error) {
      return (<div className="message error">Lo Sentimos algo salió mal, intentalo más tarde</div>);
    } else if(this.state.sending) {
      return (<div className="message sending">Enviando...</div>);
    } else {
      return (<div className="message success"> Hemos recibido tu mensaje.</div>);
    }
  }

  render() {
    const isRequired = this.state.email.length === 0 && this.state.phone.length < 10;
    const showMessage = this.state.showMessage;
    return(
      <div className="form-container">
        {this._printMessage()}
        <form onSubmit={this.handleSubmit}>
          
          <label>Email:</label>
          <input type="email" 
            name="email" 
            value={this.state.email} 
            onChange={this.handleChange}
            required={isRequired}
            placeholder="example@mail.com"/>
          
          <label>Nombre:</label>
            <input type="text" 
              name="name" 
              value={this.state.name} 
              onChange={this.handleChange}
              required 
              placeholder="Juan Peréz"/>
         
          <label>Teléfono:</label>
          <input 
              type="tel" 
              name="phone" 
              value={this.state.phone} 
              onChange={this.handleChange} 
              min="10" 
              max="10" 
              pattern="\+?[0-9]*" 
              title="Solo son permitidos números y el signo +"
              required={isRequired}
              placeholder="4445555555"/>

          <label>Mensaje:</label>
          <textarea 
            name="message" 
            value={this.state.message} 
            onChange={this.handleChange}
            rows="5" 
            cols="50" required
            placeholder="Mensaje"/>

          <input type="submit" value="Enviar" disabled={showMessage} />
        </form>
      </div>  
    );
  }
}

