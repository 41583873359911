import React from 'react';
import { Link } from 'react-router-dom';
import SubMenuItems from './submenu-item.js';

export default class MenuItem extends React.Component {

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showDropDown: props.showDropDown,
      isMobile: props.isMobile,
      item: props.item
    }; 
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);  
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);  
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClick(e) {
    // validate if click is outside of div drop-down
    if(this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState((state, props) => ({
        showDropDown: false,
        isMobile: this.state.isMobile,
        item: this.state.item
      }));
    }
  }

  toggleMenu() {
    this.setState((state, props) => ({
      showDropDown: !this.state.showDropDown,
      isMobile: this.state.isMobile,
      item: this.state.item
    }));
  }

  renderSubMenu() {
    let item = this.state.item;
    return this.state.showDropDown ?
      (<div className="dropdown-content show" ref={this.setWrapperRef} >
        <SubMenuItems subMenus={item.subMenus} onClick={() => this.toggleMenu()} />
       </div>) : 
      (<div className="dropdown-content" ref={this.setWrapperRef}> </div>); 
  }
  
  render() {
    let item = this.state.item;
    if (!item.hasChilds) {
      return <li> <Link to={item.link}> {item.text} </Link> </li>;
    } else {
       return (
        <li className="dropdown">
          <button onClick={() => this.toggleMenu()} className="btn-dropdown"> {item.text} </button>
          {this.renderSubMenu()}
        </li>
       );
    }
  }
}


