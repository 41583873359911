import React from 'react';
import SocialMediaIcon from '../../components/social-media-icon';
import linkedin from '../../assets/hollow-cut-linkedin.svg';
import twitter from '../../assets/hollow-cut-twitter.svg';
import facebook from '../../assets/hollow-cut-facebook.svg';
import './footer.css';

export default class Footer extends React.Component {
  
  constructor(props) {
    super(props);
    this.labelAddress = props.footer['label-address'];
    this.labelPhone = props.footer['label-phone'];
    this.labelEmail = props.footer['label-email'];
    this.icons = props.footer.icons;
    this.address = props.footer.address;
    this.phone = props.footer.phone;
    this.followus = props.footer['follow-us'];
    this.email = props.footer.email;
  }

 renderSocialMediaIcons() {   
    return this.icons   
      .map((icon, index) => <SocialMediaIcon link={icon.link} name={icon.name} image={this.getImage(icon.name)} key={icon.name + index} /> );
  } 
  
  getImage(iconName) {         
    return ({                  
      "linkedin": linkedin,    
      "facebook": facebook,        
      "twitter": twitter
    })[iconName];
  }

  render() {
    const phonRef = 'tel:'+this.phone.replaceAll('-', '');
    const mailRef = 'mailto:'+this.email;
    return (
      <div className="footer">
        <div className="location">
           <label> {this.labelAddress}:</label> <label> {this.address} </label>
           <label> {this.labelPhone}:</label><a href={phonRef}> {this.phone} </a>
           <label> {this.labelEmail}:</label><a href={mailRef}> {this.email} </a>
        </div>
        <div className="social-media">
          {this.renderSocialMediaIcons()}
        </div>
      </div>
    );
  }
}
