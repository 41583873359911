import React from 'react';
import './menu.css';
import MenuItem from './menu-item.js';

export default class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.elements = props.menu.elements;
  }

  renderMenu() {
    return this.elements
      .map((item, index) => <MenuItem item={item} isMobile={false} key={item.text+'_m_'+index}  />);
  }

  renderMobileMenu() {
    let mobileMenu = [
      {
        text: "Menu",
        hasChilds: true,
        link: "#",
        subMenus: this.elements
      }
    ];
    return mobileMenu
      .map( (menu, index) => <MenuItem key={index} item={menu} isMobile={true} />);
  }

  render() {
    return (
      <div className="menu">
        <div className="items">
          <ul className="sm-menu">
            {this.renderMobileMenu()}
          </ul>
          <ul className="normal-menu">
            {this.renderMenu()}
          </ul>
        </div>
      </div>
    );
  }
}
