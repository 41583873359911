import React from 'react';
import { Link } from 'react-router-dom';

const SubMenuItems = function(props) {
  return props.subMenus
    .map((subMenuItem, index) => !subMenuItem.hasChilds ? 
      <Link to={subMenuItem.link} key={index+subMenuItem.text+'_m'} onClick={() => props.onClick()}> {subMenuItem.text} </Link>
      : <CollapsibleMenu item={subMenuItem} key={index+subMenuItem.text+'_m'} onClick={() => props.onClick()} />);
};

class CollapsibleMenu extends React.Component {
 
  constructor(props) {
    super(props);
    this.state={
      subItem: props.item,
      isMenuOpen: false
    };
    this.closeMenu = props.onClick;
  }

  toggleMenu() {
    this.setState((state, props) => ({
      subItem: this.state.subItem,
      isMenuOpen: !this.state.isMenuOpen
    }));
  }

  render() {
    let item = this.state.subItem;
    if(this.state.isMenuOpen) {
      return (
        <span>
          <button className="collapsible" onClick={() => this.toggleMenu()}> {item.text} </button>
          <div className="collapsible-content show">
            <SubMenuItems subMenus={item.subMenus} onClick={this.closeMenu} /> 
          </div>
        </span>
      );
    } else {
      return (
        <span>
          <button className="collapsible" onClick={() => this.toggleMenu()}> {item.text} </button>
        </span>
      );
    }
  }

}

export default SubMenuItems;
