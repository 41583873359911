import React from 'react';


export default class HeaderImg extends React.Component {

 constructor(props) {
  super(props);
  this.image = props.img;
  this.children = props.children;
  this.height = !props.height ? '100vh': props.height;
 }

 render() {
  
  const style = {
    backgroundImage: 'url('+this.image+')',
    display: 'flex',
    width: '100vw',
    height: this.height,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  return (
    <div style={style} className="img-container">
        {this.children}
    </div>
  );
 }

}



