import React from 'react';
import './social-media-icon.css';


export default class SocialMediaIcon extends React.Component {

  constructor(props) {
    super(props);
    this.link = props.link;
    this.image = props.image;
    this.name = props.name;
  }

  render() {
    return (
      <a href={this.link} target="_blank" className="social-media-icon"  rel="noopener noreferrer" >
        <img src={this.image} alt={this.name}/>
      </a>
    );
  }
}

