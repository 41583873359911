import React from 'react';
import './service-description.css';


export default class ServiceDescription extends React.Component {
  
  constructor(props) {
    super(props);
    this.title = props.serviceInfo.title;
    this.content = props.serviceInfo.content;
    this.key = props.serviceInfo.key;
  }

  renderServiceContent() {
    return this.content.map((paragraph, index) => {
      let id = this.key + index;
      if(paragraph.isList) {
        let content = paragraph.text.split('|')
          .map((text) => (<li> {text} </li>));
        return (<ul> {content} </ul>);
      } else {
        return (<p key={id}>{paragraph.text}</p>);
      }
    });
  }

  render() {
    return (
      <div className="section-service">
        <div className="card service">
          <h1> {this.title} </h1>
            <hr />
          {this.renderServiceContent()}
        </div>
      </div>
    );
  }
}
