import React from 'react';
import { useParams } from 'react-router-dom';
import './services.css';
import ServiceDescription from './service-description';

export default function Services(props) {
  
  let { service } = useParams();
  let serviceInfo = service ? props.services[service] : props.services["nosotros"];
  
  return (
    <ServiceDescription serviceInfo={serviceInfo} key={serviceInfo.key} />
  );
}



