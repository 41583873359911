import React from 'react';
import ContactUs from '../../components/contact-us';
import './contact.css';
import aviso from '../../assets/aviso_de_privacidad.pdf';

export default function Contact(props) {
  return (
    <div className="contact-container">
      <div className="text">
        <p> Escríbenos un mensaje y alguien de nuestro equipo te contactará
        tan pronto como sea posible </p>
        <p> Visita nuestro <a href={aviso}> avíso de privacidad </a> </p>
      </div>  
      <ContactUs serviceApi={props.serviceApi} sendTo={props.sendTo}/>
    </div>
  );
}
